import Webcam from "react-webcam";
import { useCallback, useRef, useState } from "react";
import { TextField, Button } from "@mui/material";
import "./RegistrationWebcam.css";

const RegistrationWebcam = ({imgSrc,setImgSrc, registerImage, registerMessage, setRegisterMessage}) => {
    const webcamRef = useRef(null);
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    //const [imgSrc, setImgSrc] = useState(null);

    const capture = () => {
        console.log("ding")
        console.log(firstName)
        console.log(lastName)
        console.log("ding2")
        if (!firstName || !lastName) {
            setRegisterMessage(<h1 style={{color: "white", backgroundColor: "rgba(0,0,0,0.5)"}}>Please enter a First and Last name</h1>)
            return;
        }
        const imageSrc = webcamRef.current.getScreenshot();
        setRegisterMessage(<h1 style={{color: "white", backgroundColor: "rgba(0,0,0,0.5)"}}>Registering...</h1>)
        registerImage(imageSrc, firstName, lastName);
    }

    const videoConstraints = {
        facingMode: "user"
    }

    return (
        <div className="container" style={{marginLeft: "auto", marginRight: "auto", display: "block", width: "75%", height: "75%", padding: "10px"}}>
            {imgSrc ? (
                <img src={imgSrc} alt="webcam" />
            ): (
                <div style={{width: "100%"}}>
                <div className="formDiv">
                    <TextField id="firstName" label="First Name" variant="filled" required sx={{backgroundColor: "rgba(255,255,255,0.90)", width: "15ch", margin: "1px"}} onChange={(e) => {setFirstName(e.target.value)}} />
                    <TextField id="lastName" label="Last Name" variant="filled" required sx={{backgroundColor: "rgba(255,255,255,0.90)", width: "15ch", margin: "1px"}} onChange={(e) => {setLastName(e.target.value)}} />
                    <br /><Button onClick={(e)=>{e.preventDefault();capture();}} variant="contained">Capture photo</Button>
                    <div>
                        {registerMessage}
                    </div>
                </div>
                <div className="webcamDiv">
                <Webcam
                    height={"100%"}
                    width={"100%"}
                    ref={webcamRef}
                    screenshotFormat="image/jpeg"
                    screenshotQuality={0.9}
                    videoConstraints={videoConstraints}
                    style={{float:"right"}}
                />
                </div>
                </div>
            )}
        </div>
    );
};

export default RegistrationWebcam;