import { Button, Hidden } from "@mui/material";
import * as faceapi from "face-api.js";
import React from "react";

function CustomWebcam({
  imgReady,
  setImgReady,
  sendImage,
  uploadResultMessage,
}) {
  const initialized = React.useRef(false);
  const handleInitialized = React.useRef(false);
  const waiting = React.useRef(false);
  const timerRunning = React.useRef(false);
  const sendingFace = React.useRef(false);
  const videoReady = React.useRef(false);
  const [facingCamera, setFacingCamera] = React.useState("user");
  const [modelsLoaded, setModelsLoaded] = React.useState(false);
  const [captureVideo, setCaptureVideo] = React.useState(false);

  const videoRef = React.useRef();
  const videoHeight = 480;
  const videoWidth = 640;
  const canvasRef = React.useRef();

  React.useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;
      const loadModels = async () => {
        const MODEL_URL = process.env.PUBLIC_URL + "/models";

        Promise.all([
          faceapi.nets.tinyFaceDetector.loadFromUri(MODEL_URL),
          faceapi.nets.faceLandmark68Net.loadFromUri(MODEL_URL),
          faceapi.nets.faceRecognitionNet.loadFromUri(MODEL_URL),
          faceapi.nets.faceExpressionNet.loadFromUri(MODEL_URL),
        ]).then(setModelsLoaded(true));
      };
      loadModels();
      console.log("Use Effect Fire");
      setTimeout(startVideo, 1000);
    }
    return () => {
      clearTimeout(timer);
      closeWebcam();
    };
  }, []);

  const canPlay = () => {
    console.log("starting play");
    videoRef.current.play();
    videoReady.current = true;
  };

  const startVideo = () => {
    console.log("startVideo");
    setCaptureVideo(true);
    navigator.mediaDevices
      .getUserMedia({
        video: { width: 300, facingMode: facingCamera },
        audio: false,
      })
      .then((stream) => {
        let video = videoRef.current;
        video.addEventListener("canplay", canPlay);
        video.srcObject = stream;
        //video.play();
      })
      .catch((err) => {
        console.error("error:", err);
      });
  };

  let timer = null;
  function setupTimer() {
    console.log("setupTimer");
    console.log(timerRunning.current);
    if (timerRunning.current) return;
    timerRunning.current = true;
    console.log("SetupTimer");
    timer = setInterval(async () => {
      console.log(timer);
      if (canvasRef && canvasRef.current && videoReady.current) {
        canvasRef.current.innerHTML = faceapi.createCanvasFromMedia(
          videoRef.current
        );
        const displaySize = {
          width: videoWidth,
          height: videoHeight,
        };

        faceapi.matchDimensions(canvasRef.current, displaySize);

        const detections = await faceapi.detectAllFaces(
          videoRef.current,
          new faceapi.TinyFaceDetectorOptions()
        );
        //console.log(detections.length);
        if(!canvasRef) return;
        const ctx = canvasRef.current.getContext("2d");
        ctx.drawImage(videoRef.current, 0, 0, videoWidth, videoHeight);
        if (detections.length > 0 && imgReady) {
          clearInterval(timer);
          console.log("Face detected");
          timerRunning.current = false;
          setImgReady(false);
          if (!sendingFace.current) {
            sendingFace.current = true;
            setTimeout(() => {
              console.log("Sending face");
              console.log(canvasRef.current.toDataURL("image/jpeg"));
              setImgReady(false);
              sendImage(canvasRef.current.toDataURL("image/jpeg"));
              //pretend send face here:
              sendingFace.current = false;
              if (!waiting.current) {
                waiting.current = true;
                setTimeout(() => {
                  waiting.current = false;
                  timer = null;
                  setupTimer();
                }, 5000);
              }
            }, 500);
          }
        }

        //const resizedDetections = faceapi.resizeResults(detections, displaySize);

        //canvasRef && canvasRef.current && canvasRef.current.getContext('2d').clearRect(0, 0, videoWidth, videoHeight);
        //canvasRef && canvasRef.current && faceapi.draw.drawDetections(canvasRef.current, resizedDetections);
        //canvasRef && canvasRef.current && faceapi.draw.drawFaceLandmarks(canvasRef.current, resizedDetections);
        //canvasRef && canvasRef.current && faceapi.draw.drawFaceExpressions(canvasRef.current, resizedDetections);
      }
    }, 100);
  }

  const handleVideoOnPlay = () => {
    console.log(handleInitialized.current);
    if (!handleInitialized.current) {
      handleInitialized.current = true;
      setupTimer();
    }
  };

  const closeWebcam = () => {
    setCaptureVideo(false);
    handleInitialized.current = false;
    if (videoRef.current) {
      videoRef.current.pause();
      videoRef.current.srcObject.getTracks()[0].stop();
      videoRef.current.removeEventListener("canplay", canPlay);
    }
  };

  const swapCamera = () => {
    //alert(facingCamera)
    if (facingCamera === "user") {
      clearInterval(timer);
      timerRunning.current = false;
      videoReady.current = false;
      closeWebcam();
      setTimeout(() => {
        setFacingCamera("environment");
        startVideo();
      }, 500);
      //alert(facingCamera)
    } else {
      clearInterval(timer);
      timerRunning.current = false;
      videoReady.current = false;
      closeWebcam();
      setTimeout(() => {
        setFacingCamera("user");
        startVideo();
      }, 500);
    }
  };

  return (
    <div>
      <div style={{ textAlign: "center", padding: "10px" }}>
        {/* {captureVideo && modelsLoaded ? (
          <button
            onClick={closeWebcam}
            style={{
              cursor: "pointer",
              backgroundColor: "green",
              color: "white",
              padding: "15px",
              fontSize: "25px",
              border: "none",
              borderRadius: "10px",
            }}
          >
            Close Webcam
          </button>
        ) : (
          <button
            onClick={startVideo}
            style={{
              cursor: "pointer",
              backgroundColor: "green",
              color: "white",
              padding: "15px",
              fontSize: "25px",
              border: "none",
              borderRadius: "10px",
            }}
          >
            Open Webcam
          </button>
        )} */}
      </div>
      {captureVideo ? (
        modelsLoaded ? (
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                padding: "10px",
                zIndex: "-1",
              }}
            >
              <video
                ref={videoRef}
                height={videoHeight}
                width={videoWidth}
                onPlay={handleVideoOnPlay}
                style={{ borderRadius: "10px", visibility: "hidden" }}
                autoPlay={true}
                playsInline={true}
                muted={true}
              />
              <canvas
                ref={canvasRef}
                style={{ position: "absolute", visibility: "hidden" }}
              />
            </div>

            <div
              style={{
                color: "white",
                position: "absolute",
                top: "50%",
                textAlign: "center",
                width: "100%",
                margin: "auto",
              }}
            >
              {uploadResultMessage}
            </div>
          </div>
        ) : (
          <div>loading...</div>
        )
      ) : (
        <></>
      )}
    </div>
  );
}

export default CustomWebcam;
