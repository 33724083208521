import { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route, Link, Outlet } from "react-router-dom";
import "./App.css";
import CustomWebcam from "./AutoCustomWebcam";
import Register from "./RegistrationWebcam";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import PersonAdd from "@mui/icons-material/PersonAdd";
import FaceIcon from "@mui/icons-material/Face";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import RegistrationWebcam from "./RegistrationWebcam";
const uuid = require("uuid");

const theme = createTheme({
  palette: {
    primary: {
      main: "#631111",
    },
    secondary: {
      main: "#311b92",
    },
  },
});

function App() {
  //const [image, setImage] = useState("");
  const [imgReady, setImgReady] = useState(true);
  const [uploadResultMessage, setUploadResultMessage] = useState(
    <h1 style={{ color: "white", backgroundColor: "rgba(0,0,0,0.5)" }}>
      Waiting to scan...
    </h1>
  );
  const [isAuth, setAuth] = useState(false);

  const [registration, setRegistration] = useState(false);
  const [registerMessage, setRegisterMessage] = useState(<h1 style={{color: "white", backgroundColor: "rgba(0,0,0,0.5)"}}></h1>);

  function base64ToArrayBuffer(base64) {
    console.log(base64);
    var binaryString = atob(base64);
    var bytes = new Uint8Array(binaryString.length);
    for (var i = 0; i < binaryString.length; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes.buffer;
  }

  function registerImaga(image, firstName, lastName) {
    console.log(firstName);
    console.log(lastName);
    console.log(image);
    if(!firstName || !lastName || !image) return;
    fetch(
      `https://m41grabwak.execute-api.us-east-1.amazonaws.com/dev/register`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          "firstName": firstName,
          "lastName": lastName,
          "image": image.split(",")[1]
        })
      }
    ).then((data) => {
      console.log("Response from register:")
      console.log(data);
      if(data.status === 200){
        setRegisterMessage(<h1 style={{color: "white", backgroundColor: "rgba(0,0,0,0.5)"}}>Successfully registered!</h1>)
        setTimeout(()=>{
          setRegisterMessage(<h1 style={{color: "white", backgroundColor: "rgba(0,0,0,0.5)"}}></h1>)
        },2500);
      }else{
        setRegisterMessage(<h1 style={{color: "white", backgroundColor: "rgba(255,0,0,0.5)"}}>There was an error...</h1>)
        setTimeout(()=>{
          setRegisterMessage(<h1 style={{color: "white", backgroundColor: "rgba(0,0,0,0.5)"}}></h1>)
        },2500);
      }
    })
  }

  function sendImage(image) {
    console.log(image);
    if (!image) return;
    const visitorImageName = uuid.v4();
    //const sendimgdata = image.data
    //return;

    fetch(
      `https://m41grabwak.execute-api.us-east-1.amazonaws.com/dev/rockfitness-visitor-images/${visitorImageName}.jpeg`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "image/jpeg",
        },
        body: base64ToArrayBuffer(image.split(",")[1]),
        //body: sendimgdata
      }
    )
      .then(async () => {
        console.log("ding1");
        const response = await authenticate(visitorImageName);
        console.log("ding2");
        if (response.Message === "Success") {
          setAuth(true);
          setUploadResultMessage(
            <h1 style={{ color: "green", backgroundColor: "rgba(0,0,0,0.5)" }}>
              Hi {response["firstName"]} {response["lastName"]}, welcome to{" "}
              <span style={{ fontFamily: "Orbitron" }}>Rock Fitness</span>!
            </h1>
          );
          setTimeout(() => {
            setImgReady(true);
            setUploadResultMessage(
              <h1
                style={{ color: "white", backgroundColor: "rgba(0,0,0,0.5)" }}
              >
                Waiting to scan...
              </h1>
            );
          }, 5000);
        } else {
          setAuth(false);
          setUploadResultMessage(
            <h1 style={{ color: "red", backgroundColor: "rgba(0,0,0,0.5)" }}>
              ***Authentication failed***
            </h1>
          );
          setTimeout(() => {
            setImgReady(true);
            setUploadResultMessage(
              <h1
                style={{ color: "white", backgroundColor: "rgba(0,0,0,0.5)" }}
              >
                Waiting to scan...
              </h1>
            );
          }, 5000);
        }
      })
      .catch((error) => {
        setAuth(false);
        setUploadResultMessage("There is an error durring auth process");
        console.log(error);
      });
  }

  async function authenticate(visitorImageName) {
    const requestUrl =
      "https://m41grabwak.execute-api.us-east-1.amazonaws.com/dev/customer?" +
      new URLSearchParams({
        objectKey: `${visitorImageName}.jpeg`,
      });
    return await fetch(requestUrl, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        return data;
      })
      .catch((error) => console.error(error));
  }

  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <Box sx={{ flexGrow: 1 }}>
          <Routes>
            <Route path="/" element={<Appbar />}>
              <Route
                index
                element={
                  <CustomWebcam
                    // imgSrc={image}
                    // setImgSrc={setImage}
                    imgReady={imgReady}
                    setImgReady={setImgReady}
                    sendImage={sendImage}
                    uploadResultMessage={uploadResultMessage}
                  />
                }
              />
              <Route
                path="register"
                element={
                  <RegistrationWebcam
                    registerImage={registerImaga}
                    registerMessage={registerMessage}
                    setRegisterMessage={setRegisterMessage}
                  />
                }
              />
            </Route>
          </Routes>
        </Box>
      </ThemeProvider>
    </BrowserRouter>
  );
}

const Appbar = () => {
  return (
    <>
      <AppBar position="static">
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="primary"
            aria-label="menu"
            sx={{ mr: 2 }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            style={{ fontFamily: "Orbitron" }}
            variant="h5"
            component="div"
            sx={{ flexGrow: 1 }}
          >
            ROCK FITNESS
          </Typography>
          <nav>
            <IconButton component={Link} to="/">
              <FaceIcon sx={{ color: "white" }} />
            </IconButton>
            <IconButton component={Link} to="/register">
              <PersonAdd sx={{ color: "white" }} />
            </IconButton>
          </nav>
        </Toolbar>
      </AppBar>

      <Outlet />
    </>
  );
};

export default App;
